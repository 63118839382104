.features-container {
  max-width: 65%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.features-heading {
  text-align: left;
  font-family: Inter;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.features-title {
  color: #fff;
  text-align: left;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.features-description {
  margin: 30px auto;
  width: 100%;
  color: #fff;
  text-align: left;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.feature-list {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.feature-item {
  color: #fff;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
}

/* Responsive styles */
@media (max-width: 768px) {
  .features-container {
    padding: 10px;
    max-width: 90%;
  }

  .features-heading {
    font-size: 22px;
  }

  .features-description {
    font-size: 18px;
    margin: 20px 0;
  }

  .feature-item {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .features-heading {
    font-size: 20px;
    width: 100%;
  }

  .features-description {
    font-size: 16px;
    margin: 15px 0;
  }

  .feature-item {
    font-size: 16px;
  }
}
