.description-bg {
  background: #000;
}

/* .existing-features-container {
  color: #fff;
  text-align: center;
  font-family: Roboto, sans-serif; 
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  list-style-type: none; 
  padding: 0; 
} */
.arrow {
  margin-right: 10px;
  transition: transform 0.3s ease;
  font-weight: bold; /* Make the arrow bold */
}

.expanded {
  transform: rotate(
    90deg
  ); /* Optional: rotate the arrow to indicate expansion */
}

/* .faq-answer {
  display: block;
  margin-top: 5px;
} */
.faq-container {
  width: 90%;
  margin: auto;
  padding-bottom: 15px;
}
.question {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #cb39c9;
  text-align: left;
  color: #fff;
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}
.answer {
  color: rgb(85, 85, 85);
  display: flex;
  justify-content: space-between;
  padding: 25px 25px;
  background-color: #ffffff;
  text-align: left;
  color: #636363;
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* border-radius: 20px; */
}
.custom-ol {
  padding-left: 1.5rem;
}

.custom-ol li {
  margin-bottom: 0.5rem; /* Adjust the space between items as needed */
  font-size: 20px;
  padding: 10px 0;
}
/* nov 5 end */
.existing-features-container li {
  margin: 8px 0; /* Add margin between list items */
}

/* Responsive styles */
@media (max-width: 768px) {
  .faq-container {
    width: 90%;
    margin: auto;
    padding-bottom: 15px;
  }
  .question {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 25px;
    background-color: #cb39c9;
    text-align: left;
    color: #fff;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }
  .answer {
    color: rgb(85, 85, 85);
    display: flex;
    justify-content: space-between;
    padding: 25px;
    background-color: #ffffff;
    text-align: left;
    color: #636363;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    /* border-radius: 20px; */
  }
  .custom-ol {
    padding-left: 1.5rem;
  }
}
