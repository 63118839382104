/* src/MessengerIcon.css */
.messenger-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1E88E5;
    color: #ffffff;
    border-radius: 30px;
    cursor: pointer;
    width: 55px;
    height: 55px;
    border: none;
    cursor: pointer;
    animation: jump 3s infinite;
}

@keyframes jump {

    0%,
    40% {
        transform: translateY(0);
    }

    70% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0);
    }
}

.chat-header {
    width: 100%;
    height: 80px;
    background-color: #0084FF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-header p {
    color: #fff;
    text-align: center;
    line-height: 100px;
    margin: 0;
}

.profile-image {
    width: 40px;
    height: 40px;
    border-radius: 80%;
    margin-right: 10px;

}

.chat-section {
    position: fixed;
    bottom: 70px;
    right: 20px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    width: 300px;
    overflow: hidden;
}

.loading {
    text-align: center;
    font-size: 16px;
    padding: 10px;
}

.message-box {
    background-color: rgb(199, 199, 199);
    border-radius: 5px;
    padding: 5px 10px;
    width: auto;
    text-align: left;
    margin: 10px;
    display: inline-block;
}

.btn-container {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.chat-section button {
    background-color: #0084FF;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 25px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
}

.chat-section button:hover {
    background-color: #005BBB;
}