/* Container style */
.scroll-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: auto;
    align-items: center;
    justify-content: center;
    padding: 3px;
  }
  
  /* Button style */
  .scroll-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cb39c9;
    color: #ffffff;
    border-radius: 30px;
    cursor: pointer;
    width: 55px;
    height: 55px;
    border: none;
  }

  .messenger-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1E88E5;
    color: #ffffff;
    border-radius: 30px;
    cursor: pointer;
    width: 55px;
    height: 55px;
    border: none;
  }

  