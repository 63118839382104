.blog-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    position: relative;
}

.Blog-txt {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
}

/* Styles for mobile phones*/
@media only screen and (max-width: 600px) {
    .blog-wrapper {
        margin-top: 150px;
      }
      .blog-txt {
        font-size: 18px;
      }
}

/* Styles for Tablet */
@media only screen and (min-width: 601px) and (max-width: 900px) {
    .blog-wrapper {
        margin-top: 50px;
      }
      .blog-txt {
        font-size: 24px;
      }
}