.component-wrapper {
  background-color: black;
}
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body.blog-body {
  min-height: 100vh;
  background-color: #000;
}
img{
  max-width: 100%;
}
body.blog-body {
  min-height: 100vh;
  background-color: #000;
  position: relative;
}
.common-blog {
  color: #fff;
  box-shadow: 0 0 17px 0 #cb39c9;
  border: 1px solid #cb39c9;
}
.common-blog img {
  width: 100%;
}
.blog-head {
  text-align: center;
  color: #fff;
  margin-bottom: 40px;
}
.blog-head h1 {
  font-size: 50px;
}
section.all-blog {
  background-image: url(../public/assets/blogs/blog-bg.svg);
  padding: 100px 0 0px 0;
  background-size: cover;
  background-color: #000000c2;
  background-blend-mode: overlay;
  color: #fff;
}
.blog-body .all-blog {
  padding-bottom: 150px;
}

.common-blog-content {
  padding: 10px 20px 20px;
  text-align: center;
}
.common-recent-detail{
  position: sticky;
  top: 150px;
  max-height: 100vh;
  overflow-x: auto;
  padding-bottom: 20px;
}
.recent-post {
  color: #fff;
  border: 1px solid #cb39c9;
  padding: 20px;
  margin-bottom: 20px;
}
.recent-post h3 {
  color: #fff;
  font-size: 25px;
  margin-bottom: 20px;
}
.common-blog-content p {
  font-size: 15px;
  margin-bottom: 10px;
}
.common-blog-content h4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 30px;
  line-height: 1.4;
}
.common-post a{
  display: flex;
  color: #fff;
  margin-bottom: 20px;
  align-items: center;
  gap: 15px;
  text-decoration: none;

img {
      max-width: 100px;
      width: 100%;
  }
  p{
      margin-bottom: 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
  }
}
.common-blog-content button {
  border: none;
  border-radius: 35px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 12px 24px;
  transition: .3s;
  box-shadow: 0 0 17px 0 #cb39c9;
  background: #cb39c9;
}
.common-blog-content button:hover {
  color: #000;
  background-color: #fff;
}

/* blog details */
.blog-deatils-head {
  color: #fff;
  margin-bottom: 40px;
  text-align: center;
}
.blog-deatils-head h1 {
  margin-bottom: 40px;
  font-size: 50px;
}
.blog-details h1 {
  margin-bottom: 40px;
}
.blog-deatils-head img {
  width: 100%;
  box-shadow: 0 0 17px 0 #cb39c9;
  border: 1px solid #cb39c9;
}
.details-content {
  padding: 20px 0;
}
.details-content p {
  font-size: 18px;
  line-height: 1.7;
}
.details-content p a {
  color: #cb39c9;
}
.details-one {
  scroll-margin-top: 30px;
}
.details-one ul, .details-one ol {
  padding-left: 20px;
}
.details-one ul li, .details-one ol li {
  line-height: 1.6;
  margin-bottom: 5px;
  font-size: 18px;
}
.recent-post ul {
  padding-left: 20px;
  line-height: 1.7;
}
.recent-post ul li a {
  text-decoration: none;
  color: #fff;
}

.share-icon ul {
  display: flex;
  list-style: none;
  padding-left: 0;
  gap: 20px;
  margin-bottom: 0;
}
.share-icon ul li {
  width: 30px;
  height: 30px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 5px;
}
.details-one img {
  width: 100%;
  margin: 20px 0;
}
.content-space{
  margin-bottom: 2.5rem;
}
body.blog-body .copy-wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding-top: 0;
}

.footer-links ul{
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-links ul li {
  color: #cfcdcd;
  list-style-type: none;
  margin: 0 20px;
  cursor: pointer;
}

/* 12dec */
.official-head {
  text-align: center;
  margin-bottom: 40px;
}
.official-head h2 {
  font-size: 40px;
}
.common-facebook {
  border-radius: 5px;
  border: 1px solid #cb39c9;
  padding: 20px 20px 60px;
  height: 100%;
  position: relative;
}

.common-facebook h3 {
  margin-bottom: 15px;
  color: #cb39c9;
  font-size: 30px;
  font-weight: 600;
}
.official-facebook p {
  font-size: 18px;
}

.common-facebook a {
  background-color: #cb39c9;
  color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  position: absolute;
  bottom: 20px;
}

section.official-facebook {
  margin-top: 60px;
  color: #fff;
  margin-bottom: 30px;
}

.download-app h3.download-for {
  font-size: 30px;
  margin-top: 30px;
}
.download-app .click-on-the-container ol li {
  font-size: 18px;
}
.card-download.download-app.card {
  height: 100% !important;
  padding-bottom: 130px !important;
  position: relative;
  max-width: 100%;
}
.click-on-the-container button {
  border-radius: 50px;
  border: 2px solid #ffabfe;
  background: #cb39c9;
  box-shadow: 8px 8px 72px 0px #cb39c9;
  padding: 9px 30px;
  color: #fff;
  position: absolute;
  bottom: 40px;
  font-size: 18px;
}
.card-download.download-app.card .mobile-app-store-badge-parent img.image-1-icon {
  width: 100%;
}
.card-download.download-app.card .mobile-app-store-badge-parent {
  max-width: 100%;
 }

@media screen and (max-width:1200px){
 
   .common-blog-content {
      padding: 10px 15px 20px;
   }
   .common-blog-content h4 {
      margin-bottom: 20px;
      font-size: 20px;
  }
  .common-blog-content button {
      padding: 10px 24px;
  }
  .recent-post {
      padding: 20px 15px;
   }
   .common-post a {
      gap: 10px;
   }
   .common-post p {
      font-size: 14px;
  }
  .blog-head h1 {
      font-size: 40px;
  }
}

@media screen and (min-width:992px){
  .col-lg-4.mobile-show {
      display: none;
  }
}

@media screen and (max-width:991px){
.recent-post.mobile-none {
  display: none;
}
.blog-body .all-blog {
  padding-bottom: 80px;
}
}
@media screen and (max-width:767px){
   .recent-post h3 {
      font-size: 22px;
      margin-bottom: 20px;
  }
  .common-blog-content p {
      font-size: 14px;
   }
   .common-blog-content h4 {
      font-size: 18px;
  }
  .common-blog-content button {
      padding: 9px 24px;
      font-size: 14px;
  }
  .blog-head h1 {
      font-size: 30px;
  }
  .details-content {
      padding: 0;
  }
  .details-content p, .details-one ul li {
      font-size: 16px;
   }
   body .copy-wrapper {
    padding-top: 20px;
}
.common-facebook {
  margin-bottom: 30px;
    height: unset;
}
.official-head h2 {
  font-size: 30px;
}
.official-facebook p {
  font-size: 16px;
}
.common-facebook h3 {
  margin-bottom: 10px;
  font-size: 25px;
 }
 .download-app h3.download-for {
  font-size: 25px;
  margin-top: 0px;
}
.download-app .click-on-the-container ol li {
  font-size: 16px;
}
.card-download.download-app.card {
  margin-bottom: 30px !important;
  width: 100% !important;
  padding: 20px !important;
  height: unset !important;
}
.click-on-the-container button {
  position: unset;
 }
 .card-download.download-app.card .mobile-app-store-badge-parent {
  gap: 15px;
  justify-content: flex-start;
  height: unset;
}

.card-download.download-app.card ol {
  padding-left: 20px;
}

}


