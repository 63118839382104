.why-choose-image {
  background-image: url("../../public/assets/milky-way-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

.why-choose-div {
  margin-top: 200px;
}
.why-choose-container {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: center;
  flex-direction: column;
}

.why-choose-heading {
  text-align: center;
  font-size: 64px;
  color: #fff;
  font-family: Inter;
}

.why-choose-description {
  padding-top: 30px;
  width: 70%;
  margin: auto;
}

.why-choose-description p {
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-align: justify;
}

.flex-container {
  width: 65%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 40px;
  padding: 10px;
}

.flex-item {
  flex: 1 1 calc(50% - 20px);
  /* Two items per row with some gap */
  box-sizing: border-box;
  padding: 30px;
  color: black;
  border: 2px solid #9c9c9c;
  border-radius: 8px;
  /* background: linear-gradient(to right, #ca2bc7, #a01d91);s */
}

.flex-item:hover {
  background: linear-gradient(to right, #7b1e7a, #ec35ad);
  border: 2px solid black;
}

.item-title {
  font-size: 35px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #ffffff;
  font-family: Inter;
  text-align: center;
}

.item-description {
  font-size: 22px;
  color: rgb(255, 255, 255);
  font-family: Inter;
  text-align: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .why-choose-div {
    margin-top: 0px;
  }
  .flex-container {
    width: 90%;
  }
  .flex-item {
    flex: 1 1 100%;
    padding: 10px;
    /* One item per row on smaller screens */
  }
  .why-choose-description p {
    font-size: 18px;
  }
  .item-title {
    font-size: 18px;
  }
  .item-description {
    font-size: 18px;
  }
}
